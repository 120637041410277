import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Form from '../../components/Auth/components/Form';
import Head from '../../components/Head';

export const Container = styled.div`
  height: 100vh;
  min-height: 568px;
  overflow: auto;
  width: 100vw;
  position: relative;
  background-color: rgba(48,48,48,.5);
`;
export const Blind = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: #F0F1F2;
  height: 100%;
  width: 100vw;
  z-index: 2;
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const seo = {
  title: 'Личный кабинет',
  description: 'Авторизация - Личный кабинет',
  keywords: '',
}

const image = '//1.db-estate.cdn.pik-service.ru/attachment_pikru/0/1fc9e2f0-cf8f-e008-d868-0e871c376751/frame-1_612500f629e2fa9888481d936b9e69fc.jpg'

const IndexPage = () => (
  <Container>
    <Head data={seo} />
    {/*<Image src={image} />*/}
    <Blind />
    <Form />
  </Container>
);

IndexPage.propTypes = {
  image: PropTypes.string,
};

IndexPage.defaultProps = {
  image: '',
};

export default IndexPage;
